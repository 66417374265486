<template>
    <div />
</template>

<script>
import gql from "graphql-tag";

export default {
name: "LoginAs",
    created() {
        if (localStorage.getItem('mimic-token')) {
            sessionStorage.setItem('apollo-token', localStorage.getItem('mimic-token'))
            localStorage.removeItem('mimic-token')
        }
        this.$apollo.query({
            query: gql`query {
                    viewer{id,username,binaryDepth,inviteId,inviteLeg,dailyLoyalty,dailyLoyaltyUSDT
                        totals{tokens,inactive,shares,sharesA,promotionalSharesA,loyalty,held,packages,rank,totalLoyalties,totalLoyaltiesUSDT,fastStartRemaining,quaterlyVolume},country
                    }
                    }
                `,
        }).then((data) => {
            console.log(data)
            this.$store.commit('user/updateAvailable', {
                tokens: data.viewer.totals.tokens
            })
        })
        this.router.push('/')
    }
}
</script>

<style scoped>

</style>